@keyframes endless-wave {
    0% {
        transform: translateY(0)
    }

    to {
        transform: translateY(-245px)
    }
}

.animate-endless-wave {
    animation: endless-wave 20s linear infinite
}

.will-change-transform {
    will-change: transform
}

@keyframes pulseLoop {
    0% {
        opacity: 0;
        transform: scale(.25) translateZ(0)
    }

    30% {
        opacity: .4
    }

    70% {
        opacity: 0
    }

    80% {
        transform: scale(1) translateZ(0)
    }
}

.pulse[data-v-452fae4b] {
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 12s linear infinite
}

.pulse-1[data-v-452fae4b] {
    animation-delay: -4s
}

.pulse-2[data-v-452fae4b] {
    animation-delay: -8s
}