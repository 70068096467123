
/* metors */

.meteor-1 {
    position: absolute;
    width: 16rem;
    height: .125rem;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    --tw-gradient-from: #e4db39be var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    top: 169px;
    left: 3%;
    animation: meteor 10.5s linear infinite
  }
  
  .meteor-1:before {
    --tw-content: "";
    position: absolute;
    width: .125rem;
    height: .125rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    content: var(--tw-content);
    z-index: 10
  }
  
  .meteor-2 {
    position: absolute;
    width: 16rem;
    height: .125rem;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    --tw-gradient-from: #e4db39be var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    top: 20px;
    left: 52%;
    animation: meteor 11.15s linear infinite
  }
  
  .meteor-2 :before {
    --tw-content: "";
    position: absolute;
    width: .125rem;
    height: .125rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    content: var(--tw-content);
    z-index: 10
  }
  
  .meteor-3 {
    position: absolute;
    width: 16rem;
    height: .125rem;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    --tw-gradient-from: #e4db39be var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    top: 50px;
    left: 9%;
    animation: meteor 12.3s linear infinite
  }
  
  .meteor-3 :before {
    --tw-content: "";
    position: absolute;
    width: .125rem;
    height: .125rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    content: var(--tw-content);
    z-index: 10
  }

    
  .meteor-4 {
    position: absolute;
    width: 16rem;
    height: .125rem;
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
    --tw-gradient-from: #e4db39be var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    --tw-rotate: -45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    top: 100px;
    left: 78%;
    animation: meteor 12.3s linear infinite
  }
  
  .meteor-4 :before {
    --tw-content: "";
    position: absolute;
    width: .125rem;
    height: .125rem;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    content: var(--tw-content);
    z-index: 10
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -16rem;
      margin-right: -16rem
    }
  
    12% {
      opacity: 0
    }
  
    15% {
      opacity: 0;
      margin-top: 16rem;
      margin-left: -512px
    }
  
    to {
      opacity: 0
    }
  }