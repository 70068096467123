@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.remove_arrow{
  width: 100%;
}
.remove_arrow::marker{
 content: '';
}
.remove_arrow >details > summary:before{
  display: none;
}

.text-display-xl {
  font-size : 72px;
  line-height: 78px;
}

.text-display-lg {
  font-size : 64px;
  line-height: 70px;
}
.text-display-md {
  font-size : 56px;
  line-height: 64px;
}

.text-display-sm {
  font-size : 48px;
  line-height: 56px;
  font-family: "Plus Jakarta Sans";
}

.text-heading-xxl {
  font-size : 40px;
  line-height: 46px;
}
.text-heading-xl {
  font-size : 32px;
  line-height: 38px;
}

.text-heading-lg {
  font-size : 24px;
  line-height: 32px;
}

.text-heading-md {
  font-size : 20px;
  line-height: 26px;
}

.text-heading-sm {
  font-size : 18px;
  line-height: 24px;
}
.text-body-lg {
  font-size : 16px;
  line-height: 24px;
}

.text-body-md {
  font-size : 14px;
  line-height: 20px;
}

.text-body-sm {
  font-size : 12px;
  line-height: 16px;
}
.text-body-xs {
  font-size : 10px;
  line-height: 14px;
}

.text-code-sm {
  font-size : 10px;
  line-height: 14px;
  font-family: "IBM Plex Mono";
}


.text-link {
  font-size: 14px;
  line-height: 20px;
}

.text-lable {
  font-size: 14px;
  line-height: 20px;
}

.text-caption {
  font-size : 11px;
  line-height: 16px;
}

.text-code {
  font-size : 12px;
  line-height: 18px;
}

html {
  scroll-behavior: smooth;
}

body {
  overscroll-behavior: none;
}

@keyframes background-shine {
  0% {
    background-position: 0 0
  }

  to {
    background-position: -200% 0
  }
}


.animate-background-shine {
  animation: background-shine 3s linear infinite
}


@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}


@keyframes colorChange {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}



.secondary-subheading {
  background: linear-gradient(180deg, #fff 0%, rgba(229, 230, 232, 0) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  /* Add for Firefox compatibility */
  background-clip: text;
  color: transparent;
  text-shadow: -2px 3px 4px rgba(0, 0, 0, 0);
  animation: colorChange 5s infinite alternate;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #36383c;
}

::-webkit-scrollbar-track {
  background: #36383c;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba(241, 241, 241, 0.099);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(241, 241, 241, 0.181);
}

/* Handle on drag */
::-webkit-scrollbar-thumb:active {
  background: rgba(241, 241, 241, 0.181);
}



/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


@keyframes infinite-scroll {
  0% {
    transform: translate(0)
  }

  to {
    transform: translate(-100%)
  }
}

.animate-infinite-scroll {
  animation: infinite-scroll 64s linear infinite
}


/* platform css */

.lines {
  position: absolute;
  inset: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.lines .line {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 1px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 50%;
  background-color: rgb(248 248 249 / 0.05);
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #e1d22894 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgba(161, 161, 170, 0) var(--tw-gradient-via-position), var(--tw-gradient-to);
  top: -50%;
  left: 0px;
  animation: 6s ease-in-out 0s infinite normal forwards running drop;
}

.lines .line:nth-child(1) {
  margin-left: -25%;
}

.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}

.lines .line:nth-child(3) {
  margin-left: 25%;
}

.lines .line:nth-child(3)::after {
  animation-delay: 2.1s;
}



@keyframes drop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}


.circular {
  padding: 0 0 100%
}


.circular>*.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 40%;
  margin: -20%
}


.circular>*.circle:nth-of-type(1) {
  transform: rotate(0) translate(125%)
}

.circular>*.circle:nth-last-child(2):first-child~:nth-of-type(2) {
  transform: rotate(180deg) translate(125%) rotate(-180deg)
}

.circular>*.circle:nth-last-child(3):first-child~:nth-of-type(2) {
  transform: rotate(120deg) translate(125%) rotate(-120deg)
}

.circular>*.circle:nth-last-child(3):first-child~:nth-of-type(3) {
  transform: rotate(240deg) translate(125%) rotate(-240deg)
}

.circular>*.circle:nth-last-child(4):first-child~:nth-of-type(2) {
  transform: rotate(90deg) translate(125%) rotate(-90deg)
}

.circular>*.circle:nth-last-child(4):first-child~:nth-of-type(3) {
  transform: rotate(180deg) translate(125%) rotate(-180deg)
}

.circular>*.circle:nth-last-child(4):first-child~:nth-of-type(4) {
  transform: rotate(270deg) translate(125%) rotate(-270deg)
}

.circular>*.circle:nth-last-child(5):first-child~:nth-of-type(2) {
  transform: rotate(72deg) translate(125%) rotate(-72deg)
}

.circular>*.circle:nth-last-child(5):first-child~:nth-of-type(3) {
  transform: rotate(144deg) translate(125%) rotate(-144deg)
}

.circular>*.circle:nth-last-child(5):first-child~:nth-of-type(4) {
  transform: rotate(216deg) translate(125%) rotate(-216deg)
}

.circular>*.circle:nth-last-child(5):first-child~:nth-of-type(5) {
  transform: rotate(288deg) translate(125%) rotate(-288deg)
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px 0 #FAAD1B;
  }
  50% {
    box-shadow: 0 0 20px 0 rgba(250, 173, 27, 0.5);
  }
  100% {
    box-shadow: 0 0 10px 0 #FAAD1B;
  }
}

.div-with-shadow {  
  animation: glow 1.5s infinite alternate ease-in; /* adjust duration and other properties as needed */
}
