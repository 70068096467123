 .sparkle-container {
   position: relative;
 }

 .star {
   position: fixed;
   width: 2px;
   height: 2px;
   background-color: #e4cf29e4;
   /* Star color */
   border-radius: 50%;
   opacity: 0.5;
   animation: moveStar 10s infinite linear;
 }

 @keyframes moveStar {
   0% {
     left: 0px;
     top: 0px;
   }

   100% {
     transform: translate(100vw, 100vh);
     /* Move stars to the bottom right corner */
   }
 }

 .blink {
   animation: blink 1s infinite alternate;
 }

 @keyframes blink {
   0% {
     opacity: 1;
   }

   100% {
     opacity: 0;
   }
 }