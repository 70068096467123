@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  --primary-color: #f4ac38;
  --secondary-color: #221f1f;
  --tertiary-color: #ffffff;
} */

.hero-text {
  color: #fff;
  text-align: center;
  font-size: 1.7rem;
  line-height: 1.5 !important;
  font-weight: 700;
  font-family: "Quicksand", "sans-serif";

  @media (min-width: 640px) {
    font-size: 3.2rem;
  }
}

.exp-navigation .exp-ul .exp-li {
  position: relative;
  font-size: 20px;
  transition: color 0.3s ease;
}

.exp-navigation .exp-ul .exp-li.active-react {
  background-color: #221f1f;
  color: #fff;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  font-weight: bold;
}

.exp-navigation .exp-ul .exp-li:hover {
  background-color: #221f1f;
  color: #fff;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.exp-navigation .exp-ul .exp-li.active-react a::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  right: -1px;
  top: -30px;
  background: rgba(255, 0, 0, 0);
  border-radius: 50%;
  box-shadow: 15px 15px 0 #221f1f;
}

.exp-navigation .exp-ul .exp-li.active-react a::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  right: -1px;
  bottom: -30px;
  background: rgba(255, 0, 0, 0);
  border-radius: 50%;
  box-shadow: 15px -15px 0 #221f1f;
}

.exp-navigation .exp-ul .exp-li:hover a::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  right: -1px;
  top: -30px;
  background: rgba(255, 0, 0, 0);
  border-radius: 50%;
  box-shadow: 15px 15px 0 #221f1f;
}

.exp-navigation .exp-ul .exp-li:hover a::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  right: -1px;
  bottom: -30px;
  background: rgba(255, 0, 0, 0);
  border-radius: 50%;
  box-shadow: 15px -15px 0 #221f1f;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #4b4444;
}

body::-webkit-scrollbar-thumb {
  background-color: #dddddd27;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #d0901d;
}


.scrollable-content::-webkit-scrollbar {
  display: none;
}

@keyframes colorChange1 {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}


@keyframes colorChange {

  0%,
  100% {
    color: #fff;
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    color: #FAAD1B;
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }


}

.animated-sdk-live {
  /* Adjust the font size as needed */
  font-weight: 700;
  font-family: "Quicksand", "sans-serif";
  animation: colorChange 1s infinite;
  /* Change the duration and type of animation as needed */
}

.background-coming-soon {
  background-color: linear-gradient(315deg, rgba(192, 38, 211, 0.08) 0%, rgba(251, 191, 36, 0.08) 100%);
  /* filter: blur(100px); */
  ;
}


.shadow-top-left-bottom-right {
  box-shadow: 2px 1px 10px 0px #7b5f2c, -2px -1px 10px 0px #7b5f2c;
}

.heading {
  background: linear-gradient(90deg, #F8F8F9, #F4AC38);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: -2px 3px 4px rgba(0, 0, 0, 0);
  animation: colorChange1 5s infinite alternate;
}

.secondary-heading {
  background: linear-gradient(180deg, #fff 0%, rgba(229, 230, 232, 0.274) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  /* Add for Firefox compatibility */
  background-clip: text;
  color: transparent;
  text-shadow: -2px 3px 4px rgba(0, 0, 0, 0);
  animation: colorChange1 5s infinite alternate;
}

.secondary-subheading {
  background: linear-gradient(180deg, #fff 0%, rgba(229, 230, 232, 0) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  /* Add for Firefox compatibility */
  background-clip: text;
  color: transparent;
  text-shadow: -2px 3px 4px rgba(0, 0, 0, 0);
  animation: colorChange1 5s infinite alternate;
}

.gradient-border {
  border-width: 3px;
  border-image: linear-gradient(to right, #faad1b, #f4ac38);
  border-image-slice: 1;
  border-radius: 14px;
}

.animate-top-announcement-glow {
  animation: 1s ease-in-out infinite alternate o;
}